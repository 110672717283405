import { Routes, Route } from 'react-router-dom';

import BaseLayout from 'app/layout/BaseLayout';
import ROUTES from 'constants/routes';
import CountryListLoadable from 'features/country-list/CountryListLoadable';
import FormLoadable from 'features/form/FormLoadable';
import SubmitPageLoadable from 'features/submitPage/SubmitPageLoadable';

function AppRoute(): JSX.Element {
  return (
    <BaseLayout>
      <Routes>
        <Route path={ROUTES.HOME} element={<CountryListLoadable />} />
        <Route path={ROUTES.FORM_PAGE}>
          <Route index element={<FormLoadable />} />
          <Route path=':id' element={<FormLoadable />} />
        </Route>
        <Route path={ROUTES.SUCCESS} element={<SubmitPageLoadable />} />
      </Routes>
    </BaseLayout>
  );
}

export default AppRoute;
