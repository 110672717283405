import { useState, useEffect } from 'react';

import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const languages = [
  {
    label: 'English',
    code: 'en',
  },
  {
    label: 'Deutsch',
    code: 'de',
  },
  {
    label: 'Français',
    code: 'fr',
  },
  {
    label: 'Español',
    code: 'es',
  },
];

function LanguageDropdown(): JSX.Element {
  const { i18n } = useTranslation('translation');
  const [currentLanguage, updateLanguage] = useState(languages[0]);

  useEffect(() => {
    const lang = sessionStorage.getItem('lang');
    let langData = languages[0];
    if (lang) {
      langData = languages.find(({ code }) => code === lang) ?? languages[0];
    }
    updateLanguage(langData);
    i18n.changeLanguage(langData.code);
  }, [i18n, updateLanguage]);

  const handleDropdown = (data: { label: string; code: string }): void => {
    i18n.changeLanguage(data.code);
    sessionStorage.setItem('lang', data.code);
    updateLanguage(data);
  };

  return (
    <div
      style={{ position: 'absolute', right: '17px', zIndex: 9, top: '25px' }}
      className='language-dropdown-root'
    >
      <Dropdown className='language-dropdown'>
        <Dropdown.Toggle>{currentLanguage.label}</Dropdown.Toggle>

        <Dropdown.Menu>
          {languages.map(({ label, code }) => {
            return (
              <Dropdown.Item key={code} onClick={(): void => handleDropdown({ label, code })}>
                {label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default LanguageDropdown;
