import { Container, Row } from 'react-bootstrap';

import './style.css';
import LanguageDropdown from 'app/layout/LanguageDropdown';

interface IBaseLayoutProps {
  children: JSX.Element;
}

function BaseLayout({ children }: IBaseLayoutProps): JSX.Element {
  return (
    <Container fluid className='vh-100 root-div'>
      <LanguageDropdown />
      <Row className='justify-content-center align-items-center h-100'>{children}</Row>
    </Container>
  );
}

export default BaseLayout;
